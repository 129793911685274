import { TestTypeType } from 'src/__generated__/graphqlTypes';

// This is the first level
export enum TestCategories {
  US = 'US',
  UK = 'UK',
  EU = 'EU',
  English_Proficiency = 'English Proficiency',
  Medical_School_Admissions = 'Medical School Admissions',
}

// this is the second level
export enum TestSubcategories {
  English_Proficiency = 'English Proficiency Test',
  US_undergraduate = 'US Undergraduate Test',
  US_High_School = 'US High School Test',
  US_Postgraduate = 'US Postgraduate Test',
  Medical_School_Admissions = 'MedicalSchool Admissions Test',
  UK_Admissions = 'UK Admissions Test',
  Undergraduate = 'EU Undergraduate Test',
  Standardized = 'EU Standardized Test',
  EU_Admissions = 'EU Admissions Test',
}

export type TestCategoriesType = keyof typeof TestCategories;
export type TestSubcategoriesType = keyof typeof TestSubcategories;

export const TestCategoryMapping: Record<TestCategoriesType, TestSubcategoriesType[]> = {
  US: ['US_undergraduate', 'US_High_School', 'US_Postgraduate'],
  UK: ['UK_Admissions'],
  EU: ['Undergraduate', 'Standardized', 'EU_Admissions'],
  English_Proficiency: ['English_Proficiency'],
  Medical_School_Admissions: ['Medical_School_Admissions'],
};

// This is the third level
export const TestTypeMapping: Record<TestSubcategoriesType, TestTypeType[]> = {
  English_Proficiency: [TestTypeType.Ielts, TestTypeType.Toefl, TestTypeType.Pte, TestTypeType.Duolingo],
  US_undergraduate: [TestTypeType.Sat, TestTypeType.Act],
  UK_Admissions: [
    TestTypeType.Lnat,
    TestTypeType.Tsa,
    TestTypeType.Bmat,
    TestTypeType.Mat,
    TestTypeType.Tmua,
    TestTypeType.Nsaa,
    TestTypeType.Engaa,
    TestTypeType.Step,
    TestTypeType.Pat,
    TestTypeType.Elat,
    TestTypeType.Hat,
    TestTypeType.Cat,
    TestTypeType.Olat,
    TestTypeType.Mlat,
    TestTypeType.Phil,
    TestTypeType.Tumat,
    TestTypeType.Esat,
    TestTypeType.AptitudeTestingCsat,
    TestTypeType.AptitudeTestingEcaa,
    TestTypeType.Ahcaat,
    TestTypeType.Bsmat,
    TestTypeType.CambridgeAtInterviewAssessment,
  ],
  US_High_School: [
    TestTypeType.Ssat,
    TestTypeType.Isee,
    TestTypeType.Psat,
    TestTypeType.ApSubjectsTest,
    TestTypeType.IbSubjectsTest,
  ],
  US_Postgraduate: [TestTypeType.Gmat, TestTypeType.Gre, TestTypeType.Lsat, TestTypeType.Mcat],
  Medical_School_Admissions: [
    TestTypeType.Ucat,
    TestTypeType.Gamsat,
    TestTypeType.UnisrMedAdmisAssess,
    TestTypeType.ComeniusMedAdmisTest,
  ],
  Undergraduate: [],
  Standardized: [TestTypeType.Isat, TestTypeType.MedtecTestHumanitasAndPolimilan],
  EU_Admissions: [
    TestTypeType.Ebau,
    TestTypeType.IeUniversityAdmissions,
    TestTypeType.EthZurich,
    TestTypeType.EhlAdmissions,
    TestTypeType.BocconiOnline,
    TestTypeType.EnglishTolcI,
    TestTypeType.EnglishTolcE,
    TestTypeType.EnglishTolcF,
    TestTypeType.Studienkolleg,
    TestTypeType.ArchitectureTilTest,
    TestTypeType.ArchAdmisAssess,
    TestTypeType.Imat,
    TestTypeType.Bocconi,
    TestTypeType.Hpat,
    TestTypeType.Pce,
    TestTypeType.OmptA,
    TestTypeType.OmptB,
    TestTypeType.OmptC,
    TestTypeType.OmptD,
    TestTypeType.VwoBiology,
    TestTypeType.VwoChemistry,
    TestTypeType.VwoMathA,
    TestTypeType.VwoMathB,
    TestTypeType.VwoMathC,
    TestTypeType.VwoPhysics,
    TestTypeType.Humat,
    TestTypeType.UcatCamillus,
    TestTypeType.CcvxBiology,
    TestTypeType.CcvxChemistry,
    TestTypeType.CcvxMathA,
    TestTypeType.CcvxMathB,
    TestTypeType.CcvxPhysics,
    TestTypeType.Enem,
  ],
};

export const TestTypeTypeOptions: {
  value: TestTypeType | string;
  label: string;
  disabled?: boolean;
}[] = [
  {
    value: TestTypeType.Act,
    label: 'ACT',
  },
  {
    value: TestTypeType.Ahcaat,
    label: 'AHCAAT',
  },
  {
    value: TestTypeType.ApSubjectsTest,
    label: 'AP Subject Test',
  },
  {
    value: TestTypeType.ArchitectureTilTest,
    label: 'Architecture TIL Test',
  },
  {
    value: TestTypeType.Bocconi,
    label: 'BOCCONI',
  },
  {
    value: TestTypeType.Bsmat,
    label: 'BMSAT',
  },
  {
    value: TestTypeType.Cat,
    label: 'CAT',
  },
  {
    value: TestTypeType.Duolingo,
    label: 'Duolingo',
  },
  {
    value: TestTypeType.Elat,
    label: 'ELAT',
    disabled: true,
  },
  {
    value: TestTypeType.Enem,
    label: 'ENEM',
  },
  {
    value: TestTypeType.Engaa,
    label: 'ENGAA',
    disabled: true,
  },
  {
    value: TestTypeType.Gamsat,
    label: 'GAMSAT',
  },
  {
    value: TestTypeType.Gmat,
    label: 'GMAT',
  },
  {
    value: TestTypeType.Gre,
    label: 'GRE',
  },
  {
    value: TestTypeType.Hat,
    label: 'HAT',
  },
  {
    value: TestTypeType.Hpat,
    label: 'HPAT',
  },
  {
    value: TestTypeType.IbSubjectsTest,
    label: 'IB Subjects Test',
  },
  {
    value: TestTypeType.Ielts,
    label: 'IELTS',
  },
  {
    value: TestTypeType.Imat,
    label: 'IMAT',
  },
  {
    value: TestTypeType.Isat,
    label: 'ISAT Exam - Navarra University for Medicine',
  },
  {
    value: TestTypeType.Isee,
    label: 'ISEE',
  },
  {
    value: TestTypeType.Lnat,
    label: 'LNAT',
  },
  {
    value: TestTypeType.Lsat,
    label: 'LSAT',
  },
  {
    value: TestTypeType.Mat,
    label: 'MAT',
  },
  {
    value: TestTypeType.Mcat,
    label: 'MCAT',
  },
  {
    value: TestTypeType.Mlat,
    label: 'MLAT',
  },
  {
    value: TestTypeType.Nsaa,
    label: 'NSAA',
    disabled: true,
  },
  {
    value: TestTypeType.Pat,
    label: 'PAT',
  },
  {
    value: TestTypeType.Phil,
    label: 'PHILAT',
  },
  {
    value: TestTypeType.Psat,
    label: 'PSAT',
  },
  {
    value: TestTypeType.Pte,
    label: 'PTE',
  },
  {
    value: TestTypeType.Sat,
    label: 'SAT',
  },
  {
    value: TestTypeType.Ssat,
    label: 'SSAT',
  },
  {
    value: TestTypeType.Step,
    label: 'STEP',
  },
  {
    value: TestTypeType.Tmua,
    label: 'TMUA',
  },
  {
    value: TestTypeType.Toefl,
    label: 'TOEFL',
  },
  {
    value: TestTypeType.Tsa,
    label: 'TSA',
  },
  {
    value: TestTypeType.Ucat,
    label: 'UCAT',
  },
  // EU testing
  {
    value: TestTypeType.Pce,
    label: 'PCE',
  },
  {
    value: TestTypeType.OmptA,
    label: 'Online Mathematics Placement Test-A',
  },
  {
    value: TestTypeType.OmptB,
    label: 'Online Mathematics Placement Test-B',
  },
  {
    value: TestTypeType.OmptC,
    label: 'Online Mathematics Placement Test-C',
  },
  {
    value: TestTypeType.OmptD,
    label: 'Online Mathematics Placement Test-D',
  },
  {
    value: TestTypeType.VwoPhysics,
    label: 'VWO Physics',
  },
  {
    value: TestTypeType.VwoChemistry,
    label: 'VWO Chemistry',
  },
  {
    value: TestTypeType.VwoMathA,
    label: 'VWO Mathematics A',
  },
  {
    value: TestTypeType.VwoMathB,
    label: 'VWO Mathematics B',
  },
  {
    value: TestTypeType.VwoMathC,
    label: 'VWO Mathematics C',
  },
  {
    value: TestTypeType.VwoBiology,
    label: 'VWO Biology',
  },
  {
    value: TestTypeType.Humat,
    label: 'EU HUMAT',
  },
  {
    value: TestTypeType.UcatCamillus,
    label: 'UCAT Camillus',
  },
  {
    value: TestTypeType.IeUniversityAdmissions,
    label: 'IE University Admissions',
  },
  {
    value: TestTypeType.ArchAdmisAssess,
    label: 'EU Architecture Admissions Assessment',
  },
  {
    value: TestTypeType.EthZurich,
    label: 'ETH Zurich',
  },
  {
    value: TestTypeType.EhlAdmissions,
    label: 'EHL Admissions',
  },
  {
    value: TestTypeType.CcvxBiology,
    label: 'CCVX Biology',
  },
  {
    value: TestTypeType.CcvxPhysics,
    label: 'CCVX Physics',
  },
  {
    value: TestTypeType.CcvxChemistry,
    label: 'CCVX Chemistry',
  },
  {
    value: TestTypeType.CcvxMathA,
    label: 'CCVX Mathematics A',
  },
  {
    value: TestTypeType.CcvxMathB,
    label: 'CCVX Mathematics B',
  },
  {
    value: TestTypeType.BocconiOnline,
    label: 'Bocconi Online',
  },
  {
    value: TestTypeType.EnglishTolcI,
    label: 'English TOLC-I',
  },
  {
    value: TestTypeType.EnglishTolcE,
    label: 'English TOLC-E',
  },
  {
    value: TestTypeType.EnglishTolcF,
    label: 'English TOLC-F',
  },
  {
    value: TestTypeType.Studienkolleg,
    label: 'Studienkolleg(Feststellungsprüfung)',
  },
  {
    value: TestTypeType.Ebau,
    label: 'EBAU',
  },
  // extra uk testing
  {
    value: TestTypeType.Esat,
    label: 'ESAT',
  },
  {
    value: TestTypeType.AptitudeTestingCsat,
    label: `CSAT`,
  },
  {
    value: TestTypeType.UnisrMedAdmisAssess,
    label: `UniSR Medicine Admissions Test`,
  },
  {
    value: TestTypeType.ComeniusMedAdmisTest,
    label: `Comenius University Medicine Admission Test`,
  },
  {
    value: TestTypeType.ArchitectureAdmissionsAssessment,
    label: 'EU Architecture Admissions Assessment',
  },
  {
    value: TestTypeType.MedtecTestHumanitasAndPolimilan,
    label: 'MEDTEC Test-Humanitas & PoliMilan',
  },
  {
    value: TestTypeType.CambridgeAtInterviewAssessment,
    label: 'Cambridge At-Interview Assessment',
  },
];

// Depcrecated as Antd Select doesn't support 3-level hierarchy
// Organize 3-level hierarchy of the options
// TestCategories is the top level
// TestSubcategories is the second level
// TestTypeTypeOptions is the third level
// export const organizeOptions = (): {
//   label: string;
//   disabled: boolean;
//   options: {
//     label: string;
//     disabled: boolean;
//     options: {
//       value: string;
//       label: string;
//       disabled: boolean;
//     }[];
//   }[];
// }[] => {
//   const options = Object.keys(TestCategories).map((category) => {
//     const subcategories = TestCategoryMapping[category as TestCategoriesType];
//     return {
//       label: TestCategories[category as TestCategoriesType],
//       disabled: false,
//       options: subcategories.map((subcategory) => {
//         const testTypes = TestTypeMapping[subcategory as TestSubcategoriesType];
//         return {
//           label: TestSubcategories[subcategory as TestSubcategoriesType],
//           disabled: false,
//           options: testTypes.flatMap((testType) => {
//             const testTypeOption = TestTypeTypeOptions.find((option) => option.value === testType);
//             if (testTypeOption) {
//               return [
//                 {
//                   value: testTypeOption.value,
//                   label: testTypeOption.label,
//                   disabled: testTypeOption.disabled ?? false,
//                 },
//               ];
//             }
//             return [];
//           }),
//         };
//       }),
//     };
//   });
//   return options;
// };

export const organizeOptions = (): {
  label: string;
  disabled: boolean;
  options: {
    value: string;
    label: string;
    disabled: boolean;
  }[];
}[] => {
  const options = Object.keys(TestSubcategories)
    .map((subcategory) => {
      const testTypes = TestTypeMapping[subcategory as TestSubcategoriesType];
      return {
        label: TestSubcategories[subcategory as TestSubcategoriesType],
        disabled: testTypes.length === 0,
        options: testTypes.flatMap((testType) => {
          const testTypeOption = TestTypeTypeOptions.find((option) => option.value === testType);
          if (testTypeOption) {
            return [
              ...(testTypeOption.disabled
                ? []
                : [
                    {
                      value: testTypeOption.value,
                      label: testTypeOption.label,
                      disabled: false,
                    },
                  ]),
            ];
          }
          return [];
        }),
      };
    })
    .filter((o) => !o.disabled);
  return options;
};
