import { useContext, useEffect, useState } from 'react';
import { XpropsContext } from 'src/context/XpropsContext';
import type { XProps } from 'src/types/global';
import { DEFAULT_DOMAIN, XPROPS_REFRESH_EVENT } from 'src/utils/const';

export const useContextGlobalProps = (): XProps => {
  return useContext(XpropsContext);
};

export const useRootGlobalProps = (): XProps => {
  const [x, setX] = useState(window.xprops);
  useEffect(() => {
    const cb = () => {
      setX(window.xprops);
    };
    window.addEventListener(XPROPS_REFRESH_EVENT, cb);
    return () => {
      window.removeEventListener(XPROPS_REFRESH_EVENT, cb);
    };
  }, [setX]);
  return x || {};
};

export const useXDomain = (defaultDomain = DEFAULT_DOMAIN): string => {
  return useContextGlobalProps()?.domain ?? defaultDomain;
};

export const useXUserId = (): string => {
  return useContextGlobalProps()?.userId ?? '';
};
