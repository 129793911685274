import { RelationshipType } from './ManageTeamModalMultiSSM';

type ComparableField = string | boolean | null | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const compareValues = (a: ComparableField, b: ComparableField, defaultValue: any = ''): number => {
  const valA = a ?? defaultValue;
  const valB = b ?? defaultValue;

  if (valA < valB) return -1;
  if (valA > valB) return 1;
  return 0;
};

const compareRelationships = (a: RelationshipType, b: RelationshipType): number => {
  // Chain comparisons for better readability and maintenance
  return (
    compareValues(a.principalUserId, b.principalUserId) ||
    compareValues(a.relationUserId, b.relationUserId) ||
    compareValues(a.type, b.type) ||
    compareValues(a.isPrimary, b.isPrimary, false) ||
    compareValues(a.tags, b.tags, '')
  );
};

export const areDataSetsEqual = (
  originalData: RelationshipType[] | null | undefined,
  latestData: RelationshipType[] | null | undefined,
): boolean => {
  const originalRelations = originalData || [];
  const latestRelations = latestData || [];

  if (originalRelations.length !== latestRelations.length) {
    return false;
  }

  const sortedOriginal = [...originalRelations].sort(compareRelationships);
  const sortedLatest = [...latestRelations].sort(compareRelationships);

  return sortedOriginal.every((item, index) => {
    const latestItem = sortedLatest[index];
    return (
      item.principalUserId === latestItem.principalUserId &&
      item.relationUserId === latestItem.relationUserId &&
      item.type === latestItem.type &&
      (item.isPrimary ?? false) === (latestItem.isPrimary ?? false) &&
      (item.tags ?? '') === (latestItem.tags ?? '')
    );
  });
};
