import styled, { css } from 'styled-components';
import { Today, Message } from '@styled-icons/material';
import DebounceFetcherSelect from 'src/web-shared-components/base/Fields/DebounceFetcherSelect/DebounceSelect';
import theme from 'src/web-shared-components/theme';
import { StyledButtonOutlined, StyledButtonFilled } from '../CrButton';
import { ModeEdit } from '@styled-icons/material/ModeEdit';
import { Select } from 'antd';

export const StyledContainer = styled.div`
  margin-top: 26px;
  position: relative;
`;
export const StyledUl = styled.ul<{ maxHeight?: string }>`
  margin-top: 32px;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '230px')};
  overflow: auto;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    > div:first-child {
      width: 70%;
    }
    > div:nth-child(2) {
      width: 30%;
    }
  }
`;
export const StyledUserDesc = styled.div`
  margin-left: 12px;
  overflow: hidden;
  div:first-child {
    word-break: break-word;
  }
`;

export const StyledUserRole = styled.div`
  font-size: 12px;
  color: var(--color-cool-grey);
`;

export const StyledIconContainer = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-baby-blue);
  margin-left: 16px;
  border-radius: 50%;
  flex-shrink: 0;
`;
const StyledIcon = css`
  width: 20px;
  color: var(--color-indigo);
`;

export const StyledMessageIcon = styled(Message)`
  ${StyledIcon}
`;
export const StyledCalendarIcon = styled(Today)`
  ${StyledIcon}
`;

export const StyledTeamHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDebouncedSelect = styled(DebounceFetcherSelect)`
  width: 240px;
`;

export const StyledSelect = styled(Select)`
  min-width: 100%;
  .ant-select-clear {
    right: 40px;
    width: 16px;
  }
`;

//  Modal styles
export const Container = styled.div`
  min-height: 450px;
  padding: 12px 0 0 0;
  & .ant-form-item-label label {
    color: var(--color-dark-navy);
    font-weight: 600;
  }
  & .ant-form-item-explain-error {
    padding: 8px 0 15px 0;
  }
  & .ant-select-selector {
    > .ant-select-selection-item {
      font-size: 14px;
    }
    .ant-select-selection-overflow {
      align-items: center;
      max-width: 92%;
    }
    .ant-select-selection-overflow-item-rest {
      height: 24px;
      > .ant-select-selection-item {
        height: 22px;
        line-height: 22px;
        font-size: 14px;
      }
    }
    .ant-select-selection-placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: var(--color-placeholder-grey);
    }
  }
`;
export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1d1e2b;
  text-align: center;
  margin-bottom: 46px;
`;
export const ButtonContainer = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`;
export const CancelButton = styled(StyledButtonOutlined)`
  padding: 20px;
  @media (max-width: ${theme.breakPoints.xm}) {
    width: ${(props) => (props?.width ? `${props.width * 0.9}px` : 'auto')};
  }
`;
export const SubmitButton = styled(StyledButtonFilled)`
  padding: 20px;
  @media (max-width: ${theme.breakPoints.xm}) {
    width: ${(props) => (props?.width ? `${props.width * 0.9}px` : 'auto')};
  }
`;
export const StyledEditIcon = styled(ModeEdit)`
  width: 22px;
  color: var(--color-stone);
  &:hover {
    cursor: pointer;
    color: var(--color-indigo);
  }
`;
export const StyledOneLine = styled.div`
  max-width: 168px;
  font-size: 12px;
  color: var(--color-cool-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SSMField = styled.div`
  margin-bottom: 24px;
  .ant-form-item-control {
    width: 100%;
  }
  .ssm-field-label {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-dark-navy);
    display: inline-flex;
    padding-bottom: 8px;
  }
`;

export const RegionStragistField = styled.div`
  margin-bottom: 24px;
  .stra-field-label {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-dark-navy);
    display: inline-flex;
  }
`;

export const RegionStragistSubField = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  background-color: var(--color-baby-blue);
  border: 1px var(--color-pale-grey) solid;
  border-radius: 4px;
  .ant-form-item-control {
    width: 100%;
  }
  .stra-subfield-label {
    width: 30%;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-stone);
    display: block;
    text-align: center;
  }
  .ant-select {
    min-width: 0%;
    flex: 1;
    .ant-select-selector {
      border: 0px;
      border-radius: 0px;
    }
  }
`;

export const ParentWelcomeContainer = styled.div`
  margin-top: 26px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .desc {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .ant-row {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const StyledModalTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 32px;
  text-align: center;
`;

export const StyledModalContent = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 32px;
`;

export const StyledModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
`;

export const StyledCancelButton = styled(StyledButtonOutlined)`
  width: 156px;
`;

export const StyledConfirmButton = styled(StyledButtonFilled)`
  width: 156px;
`;
