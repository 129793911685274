// Polyfill PDF.js usage of Array#at
import 'array.prototype.at/polyfill';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import type { AnyFunc } from './types/anyFunction';
import config from './config';
import { QUERY_USER_INFO } from './graphql/User';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { getSimpleClient } from './graphql';
import { getLaunchOptions } from './utils/launchOptions';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { NEO_LAUNCH_MODE, REPORT_META_REFRESH_EVENT, XPROPS_REFRESH_EVENT } from './utils/const';
import { ReportMeta } from './types/global';

const validateToken = (token: string) => {
  try {
    const userId = jwtDecode<JwtPayload>(token)?.sub?.replace('|', '-') ?? '';
    if (userId) {
      return true;
    }
  } catch (e) {
    return false;
  }
};

interface IMetaMessage {
  type: 'META';
  isInit?: boolean;
  payload: Record<string, unknown>;
}

interface IInvokeReturnMessage {
  type: 'INVOKE_RETURN';
  id: string;
  returnValue: unknown;
}

interface IInvokeErrorMessage {
  type: 'INVOKE_ERROR';
  id: string;
  errorMessage: string;
}

interface IEventMessage {
  type: 'EVENT';
  payload: {
    eventName: string;
    eventData: unknown;
  };
}

interface IReeportMetaMessage {
  type: 'REPORT-META';
  payload: {
    [k: string]: string;
  };
}

type IMessage = IMetaMessage | IInvokeReturnMessage | IInvokeErrorMessage | IEventMessage | IReeportMetaMessage;

interface IUserInfo {
  user: {
    userId: string;
    email: string;
    firstName: string;
    fullName: string;
    lastName: string;
    profileImageUrl?: string;
    roles?: { roleId: string; isPrimary: boolean }[];
    tenant?: {
      id: string;
      level: number;
      name: string;
    };
  };
}

export const patch2Xprops = (src: Record<string, unknown>): void => {
  const basic = window?.xprops ?? {};
  window.xprops = { ...Object.assign(basic, src) };
  window.dispatchEvent(new CustomEvent(XPROPS_REFRESH_EVENT, {}));
};

export const patch2MetaProps = (payload: ReportMeta): void => {
  const basic = window?.reportMetaProps ?? {};
  window.reportMetaProps = {
    ...Object.assign(basic, payload),
  };
  window.dispatchEvent(new CustomEvent(REPORT_META_REFRESH_EVENT, {}));
};

const { token, userId, launchMode } = getLaunchOptions();

const getLocalDomain = () => {
  let domain = window.location.hostname;
  if (domain && domain?.includes('localhost')) {
    domain = 'http://localhost:3000';
  } else if (domain && domain?.includes('staging')) {
    domain = 'https://staging.app.crimsoneducation.org';
  } else {
    domain = 'https://app.crimsoneducation.org';
  }
  return domain;
};

const _launch = () => {
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById('root'),
  );
};

if (launchMode !== NEO_LAUNCH_MODE) {
  _launch();
} else {
  const download = (name: string, url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const getUserInfo = (() => {
    let client: ApolloClient<NormalizedCacheObject> | null = null;
    return async (token: string, userId?: string) => {
      if (!client) {
        client = getSimpleClient(new URL('/graphql', config.crimsonAppAPIUrl).href, token);
      }
      userId = userId ?? jwtDecode<JwtPayload>(token)?.sub?.replace('|', '-') ?? '';
      const { data } = await client.query<IUserInfo>({
        query: QUERY_USER_INFO,
        variables: {
          userId,
        },
      });
      return data;
    };
  })();

  const getLocalXprops = async (_token: string, _userId?: string) => {
    const promises = [] as Promise<unknown>[];
    if (!_token) {
      return;
    }
    promises.push(
      getUserInfo(_token).then((userData) => {
        const { userId, email, firstName, lastName, profileImageUrl = '', roles = [], tenant = {} } = userData.user;
        const roleInfo = JSON.stringify(roles);
        const userRoles = roles.map((x) => x.roleId);
        const role = roles.find((r) => r.isPrimary === true)?.roleId ?? '';
        const meta = {
          domain: getLocalDomain(),
          loggedInUser: {
            userId,
            email,
            firstName,
            lastName,
            profileImageUrl,
            roleInfo,
            userRoles,
            role,
            tenant,
            title: '',
          },
          getBearer: () => Promise.resolve(_token),
          download,
          authorize: async () => {
            let isAuthorized = false;
            const response = await fetch(`${config.crimsonAppAPIUrl}/authorize`, {
              credentials: 'include',
              headers: {
                Authorization: `Bearer ${_token}`,
              },
            });
            if (response.ok) {
              try {
                const data = await response.text();
                if (data !== 'OK') {
                  throw new Error(data);
                }
              } catch (e) {
                console.error('Create xprops ERROR: ', e);
                throw e;
              }
              isAuthorized = true;
            }
            return isAuthorized;
          },
        };
        patch2Xprops(meta);
      }),
    );
    promises.push(
      getUserInfo(_token, _userId).then((data) => {
        const { userId, firstName, lastName, email } = data.user;
        const meta = {
          userId,
          user: {
            userId,
            firstName,
            lastName,
            email,
          },
        };
        patch2Xprops(meta);
      }),
    );
    return Promise.all(promises);
  };

  const launch = (() => {
    let launched = false;
    return async (token?: string, userId?: string) => {
      // TODO: add error handler
      if (!launched) {
        launched = true;
        if (token) {
          await getLocalXprops(token, userId);
        }
        _launch();
      }
    };
  })();

  patch2Xprops({}); // simply init window.xprops

  const eventCache = {} as Record<string, unknown[]>;

  (() => {
    let cAPPsender: MessageEventSource;
    let counter = 0;
    const resolvers = {} as Record<string, { rej: AnyFunc; res: AnyFunc; startTime: number }>;
    const getId = () => `SC-INVOKE-${counter++}`;
    const eventHandlers = {} as Record<string, AnyFunc>;
    const registerEventEmitter = (eventName: string, cb: AnyFunc) => {
      eventHandlers[eventName] = cb;
      if (eventCache[eventName]?.length > 0) {
        const caches = eventCache[eventName].splice(0);
        caches.forEach((c) => cb(c));
      }
    };
    const unregisterEventEmitter = (eventName: string) => {
      delete eventHandlers[eventName];
    };
    const doRPC = async (functionName: string, args: Array<unknown>) => {
      const startTime = new Date().valueOf();
      const id = getId();
      const Msg = {
        type: 'INVOKE',
        payload: {
          functionName,
          id,
          args,
        },
      };
      const __ = new Promise((res, rej) => {
        cAPPsender.postMessage(Msg, { targetOrigin: '*' });
        resolvers[id] = { res, rej, startTime };
      });
      __.catch((e) => {
        throw new Error(e);
      });
      return __;
    };

    const authorize = async (...args: unknown[]) => {
      return doRPC('authorize', args);
    };

    const refreshAccessTokens = async (...args: unknown[]) => {
      return doRPC('refreshAccessTokens', args);
    };

    const getBearer = async (...args: unknown[]) => {
      return doRPC('getBearer', args);
    };

    const pushEvent = async (eventName: string, eventData: string) => {
      const msg = {
        type: 'EVENT',
        payload: {
          eventName,
          eventData,
        },
      };
      return cAPPsender?.postMessage?.(msg, { targetOrigin: '*' });
    };

    const onMessage = (d: { event: string; data: string }) => {
      const { event, data } = d;
      return pushEvent(event, data);
    };

    const history = {
      push: (tgt: string) => {
        pushEvent('history_push', tgt);
      },
    };

    const builtInMethods = {
      getBearer,
      refreshAccessTokens,
      authorize,
    };
    const builtInMethods2 = {
      registerEventEmitter,
      unregisterEventEmitter,
      onMessage,
      history,
    };
    patch2Xprops(builtInMethods2);

    window.addEventListener('message', (e) => {
      const { data, source } = e as { source: MessageEventSource | null; data: IMessage };
      const { type: messageType } = data;
      if (messageType === 'META') {
        if (!cAPPsender && source && data?.isInit) {
          cAPPsender = source;
        }
      }
      if (cAPPsender !== source) {
        return;
      }
      if (messageType === 'META') {
        patch2Xprops({ ...(data?.payload ?? {}), ...builtInMethods });
        if (data?.isInit) {
          launch();
        }
        return;
      }
      if (messageType === 'REPORT-META') {
        patch2MetaProps(data.payload);
        return;
      }
      if (messageType === 'INVOKE_ERROR') {
        const { id, errorMessage } = data;
        if (!resolvers[id]) {
          //TODO report it
          throw new Error(`debug_[MESSAGEHANDLE]==> cannot find resolvers for ${id} ${errorMessage}`);
        }
        const { rej } = resolvers[id];
        delete resolvers[id];
        rej(new Error(errorMessage));
        return;
      }
      if (messageType === 'INVOKE_RETURN') {
        const { id, returnValue } = data;
        if (!resolvers[id]) {
          //TODO report it
          throw new Error(`debug_[MESSAGEHANDLE]==> cannot find resolvers for ${id} ${returnValue}`);
        }
        const { res } = resolvers[id];
        delete resolvers[id];
        res(returnValue);
        return;
      }
      if (messageType === 'EVENT') {
        const { eventData, eventName } = data.payload;
        const handler = eventHandlers[eventName];
        // console.log('debug_route_SC_receive_EVENT==>', data.payload, 'handler as==>', kk);
        if (typeof handler === 'function') {
          eventHandlers[eventName]?.(eventData);
        } else {
          if (!eventCache[eventName]) {
            eventCache[eventName] = [];
          }
          eventCache[eventName].push(eventData);
        }
        return;
      }
    });
  })();

  if (token && validateToken(token)) {
    launch(token, userId);
  }
}
