import { gql } from '@apollo/client';
export {
  EDIT_USER_COUNTRY,
  GET_ALLRELATIONSHIPS_BY_USERS,
  GET_GUARDIANS_BY_STUDENT,
  GET_TEAM_MEMBERS_BY_STUDENT,
  GET_USERS_BY_IDS,
  ListUserType,
  QUERY_USER_INFO,
  UPDATE_YEAR_OF_APPLICATION,
  USER_INFO_FRAGMENT,
  allCurriculum,
  allPackages,
  assignPrincipalsToRelation,
  consentType,
  contractType,
  createKeyContact,
  createOrUpdateAdmissionGoal,
  createOrUpdateStudentDemographic,
  createOrUpdateStudentInternalInformation,
  createPackage,
  deleteAdmissionGoal,
  deleteFile,
  deleteKeyContact,
  detailedUserType,
  editApplicationsInfo,
  editBasicInfo,
  editContactDetails,
  editStudentEssayFolder,
  // editUserInternalInfo,
  educationType,
  fetchAssignableUsers,
  fetchFilesForUser,
  fetchLoginUserAuthorization,
  fetchProfileImageUrl,
  // fetchProfilePageUserInfo,
  getAdmissionGoals,
  getEssayFolder,
  getEssayFolderv2,
  getInternalInformation,
  getOnboardingQuizResult,
  getStudentDemographic,
  hexacoType,
  itemType,
  keyContactInfoType,
  modifyPackage,
  packageFilePresignedUploadUrl,
  packageFileUrl,
  packageType,
  paginationType,
  profileImageUploaded,
  publicUserType,
  saveKeyContactInfo,
  studentInfoType,
  triggerEventGql,
  tutorInfoType,
  updatePreferredName,
  updatePreferredPronoun,
  updateStudentContractStatus,
  updateStudentContractSubStatus,
  getStaffInfo,
} from '@crimson-education/core-shared-graphql/user';

export const fetchProfilePageUserInfo = gql`
  fragment consentType on consentType {
    id
    userId
    value
    consentType
  }

  fragment educationType on EducationType {
    id
    userId
    curriculumId
    name
    universityId
    college
    degree
    major
    minor
    yearStarted
    yearOfGraduation
    isGraduated
    currentYearLevel
    type
    status
    createdAt
  }

  fragment tutorInfoType on tutorInfoType {
    averageRating
    totalSessions
    userId
    schoolName
    schoolGraduateYear
    expertise
    curriculumId
    curriculum {
      id
      name
      shortName
    }
    subjects {
      userId
      subject
      studentCount
      subjectId
    }
    contractSubjects {
      id
      name
      subjectId
      start
    }
    university
    universities {
      ...educationType
    }
    schools {
      ...educationType
    }
    degree
    majors
    regions
    universityStartYear
    universityGraduateYear
    capacity
    studentCount
    onboardedAt
    availableHoursPerWeek
    preferredTime
    senSpecialist
    eslSpecialist
    studentAidCapacity
    allocation
  }

  fragment keyContactInfoType on keyContactInfoType {
    id
    firstName
    lastName
    email
    phone
    primaryLanguage
    languages
    city
    state
    country
    location
    gender
    relationship
    isPrimary
    createdAt
  }

  fragment studentInfoType on studentInfoType {
    userId
    schoolName
    schoolYearLevel
    schoolGraduateYear
    yearOfApplication
    curriculumId
    curriculums
    curriculum {
      id
      name
      shortName
    }
    university
    universities {
      ...educationType
    }
    schools {
      ...educationType
    }
    subjects
    packageSubjects {
      id
      name
      subjectId
      start
    }
    interests
    keyContacts {
      ...keyContactInfoType
    }
    studentType
    contractStatus
    contractSubStatus
    pronoun
    hookStatement
    essayFolder
    university
    subjects
    interests
    intendedCareer
    academicGoals
    extracurricularGoals
    aidRequirements
    aidPayRequirements
    admissionGoals
    targetCountries
    siblings
    requirement
    preparedness
    parentIntensity
    englishLevel
    parentEnglishLevel
    degree
    major
    majors
    minor
    academicAdvisorName
    accountBillingCountry
    timeSinceLastStrategyMeeting
    applicationSettings
    partnershipName
    isPaused
    pauseAt
    resumeAt
    reasonToPause
  }

  fragment publicUserType on publicUserType {
    userId
    firstName
    lastName
    fullName
    bio
    status
    email
    userRoles
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
  }

  fragment hexacoType on hexacoType {
    honesty
    emotionality
    extraversion
    agreeableness
    conscientiousness
    openness
  }

  fragment staffInfoType on staffInfoType {
    id
    userId
    division
    region
    countries
    capacity
    applicationYearCapacity
    allocationStatus
    vipAvailable
    title
    managerId
    sisterRegions
    manageRegions
    specialtyArea
    vvipAvailable
    pacificTimeAvailable
    outOfRegion
    preallocationApprovalMethod
    yearsOfWorkExperience
    bestResultUniversities {
      id
      name
    }
    specialNote
  }

  fragment detailedUserType on userType {
    userId
    firstName
    lastName
    lastAccess
    fullName
    gender
    citizenships
    email
    contactEmail
    phone
    skype
    address
    city
    state
    location
    country
    bio
    timezone
    salesforceContactId
    autoTimezone
    subscribedEmail
    createdAt
    activatedAt
    role
    userRoles
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
    status
    ethnicity
    dob
    primaryLanguage
    isTest
    languages
    profileImageUrl
    meetingRecordingConsent
    pronoun
    preferredName
    consents {
      ...consentType
    }
    studentInfo {
      ...studentInfoType
    }
    tutorInfo {
      ...tutorInfoType
    }
    staffInfo {
      ...staffInfoType
    }
    relationships {
      principalUser {
        ...publicUserType
      }
      relationUser {
        ...publicUserType
      }
      type
      isPrimary
    }
    hexaco {
      ...hexacoType
    }
    salesforceAccountUrl
    isBetaUser
    outOfOffice
    oooStartTime
    oooEndTime
  }

  query getWholeUserInfoByUserId($userId: String!) {
    user: user(userId: $userId) {
      ...detailedUserType
    }
  }
`;

export const editUserInternalInfo = gql`
  mutation editUserInternalInfo(
    $userId: String!
    $meetingRecordingConsent: Boolean
    $studentInfo: studentInfoInputType
  ) {
    data: editUser(userId: $userId, meetingRecordingConsent: $meetingRecordingConsent, studentInfo: $studentInfo) {
      meetingRecordingConsent
      studentInfo {
        studentType
        partnershipName
        summerProgramQuota
      }
    }
  }
`;

export const getFeatureFlagsByUserId = gql`
  query getFeatureFlagsByUserId($userId: String, $flags: [String!]!) {
    res: getFeatureFlagsByUserId(userId: $userId, flags: $flags)
  }
`;

export const getRiseTypeStudentSpecialDocLink = gql`
  query getRiseTypeStudentSpecialDocLink($userId: String!) {
    res: getRiseTypeStudentSpecialDocLink(userId: $userId) {
      msg
      link
    }
  }
`;

export const QUERY_USER_REGION_INFO = gql`
  query userById($userId: String!) {
    user: user(userId: $userId) {
      staffInfo {
        manageRegions
        division
      }
    }
  }
`;

export const editStudentBasicInfo = gql`
  mutation editBasicInfo(
    $userId: String!
    $firstName: String
    $lastName: String
    $email: String
    $timezone: String
    $gender: String
    $location: String
    $dob: String
    $bio: String
    $languages: [Int]
    $studentInfo: studentInfoInputType
  ) {
    editUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      timezone: $timezone
      gender: $gender
      location: $location
      dob: $dob
      bio: $bio
      languages: $languages
      studentInfo: $studentInfo
    ) {
      gender
      location
      dob
      bio
      languages
      timezone
      studentInfo {
        curriculums
      }
    }
  }
`;

export const PORFILE_BATCH_QUERY = gql`
  query batchQuery($userId: String!) {
    quizResults: getCompletedOnboardingQuizResults(userId: $userId) {
      content
    }
    getAdmissionGoals(userId: $userId) {
      id
      userId
      targetCountry
      supportSchoolNumber
      goal
      openToLiberalArts
      openToMilitaryAcademies
    }
    demographic: getStudentDemographic(userId: $userId) {
      id
      userId
      countriesCitizenship
      countriesPermanentResidency
      englishProficiency
      birthPlace
      ethnicity
    }
  }
`;
