import { Subcategories } from 'src/types/mission';

export const subcategoriesMap = {
  'School Academic': 'School Academic',
  Testing: 'Testing',
  'Academic Research & Publications': 'Academic Research & Publications',
  'Extracurricular Activities': 'Extracurricular Activities',
  'Internship & Work Experience': 'Internship & Work Experience',
  'University Courses & Summer Programs': 'University Courses & Summer Programs',
  'Competitions & Honors': 'Competitions & Honors',
  'Essential Skills & Experiences': 'Essential Skills & Experiences',
  Readings: 'Readings',
  'Relationship Building': 'Relationship Building',
  Writings: 'Writings',
  'Major, Career and Interest Exploration': 'Major, Career and Interest Exploration',
  Networking: 'Networking',
  'School Research & Preparation': 'School Research & Preparation',
  'Academic Skills & Essential Experiences': 'Academic Skills & Essential Experiences',
  'Subject Interest Development': 'Subject Interest Development',
  'UK Non-Application Year (NAY) Curriculum': 'NAY Curriculum',
  'College Admissions 101': 'College Admissions 101',
  'Academic Enrichment': 'Academic Enrichment',
};

export const categoriesMap = {
  Academics: 'ACADEMICS',
  Testing: 'TEST',
  Activities: 'ACTIVITIES',
  Honors: 'COMPETITIONS_HONORS',
  'Personal Development': 'PERSONAL_DEVELOPMENT',
  'Majors & Careers': 'CAREERS',
  'Personal info': 'Personal info',
};

export type LevelType = {
  id: string;
  description: string;
  isAssigned: boolean;
  level: string;
  points: number;
  status: string | null;
};

export type GoalType = {
  id: string;
  title: string;
  achievedLevel: string;
  levels: Array<LevelType>;
};

export const firstCharUpperCase = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const arrowRightStyle = { width: '14px', marginLeft: '8px', marginRight: '8px' };

export const listArrowRightStyle = { height: '17px' };

export const skeletonLength = 10;

export const arrowBackStyle = {
  width: '16px',
  height: '16px',
  marginRight: '12px',
  color: 'black',
  cursor: 'pointer',
};

export const closeStyle = {
  width: '14px',
  height: '14px',
  color: 'var(--color-stone)',
  cursor: 'pointer',
};

export const getTailCharS = (num: number, staticString: string): string => {
  return num === 1 ? `${num} ${staticString}` : `${num} ${staticString}s`;
};

export const missionGroupSubcategories = [Subcategories.NAY, subcategoriesMap[Subcategories.NAY]];

export const levelOrder = {
  Scout: 1,
  Seeker: 2,
  Venturer: 3,
  Voyager: 4,
  Globetrotter: 5,
};
