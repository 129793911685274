import { Modal } from 'antd';
import React from 'react';
import { StyledModalTitle } from './style';
import { StyledModalButtonContainer, StyledCancelButton, StyledConfirmButton, StyledModalContent } from './style';

type Props = {
  visible: boolean;
  setNewUpdatesVisible: React.Dispatch<React.SetStateAction<boolean>>;
  refetchTeamMembers: () => void;
  setShouldHideTeamMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const NewUpdatesDialog = ({
  visible,
  setNewUpdatesVisible,
  refetchTeamMembers,
  setShouldHideTeamMemberModal,
}: Props): JSX.Element => {
  const onCancel = () => {
    setNewUpdatesVisible(false);
    setShouldHideTeamMemberModal(false);
  };
  return (
    <Modal open={visible} footer={null} width="520px" bodyStyle={{ padding: '48px' }} onCancel={onCancel}>
      <StyledModalTitle>New Updates Detected</StyledModalTitle>
      <StyledModalContent>
        While you were making changes, new information was updated. Please reopen the &apos;Manage Team Members&apos;
        modal to review the latest updates.
      </StyledModalContent>
      <StyledModalButtonContainer>
        <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
        <StyledConfirmButton
          onClick={() => {
            refetchTeamMembers();
            setNewUpdatesVisible(false);
            setShouldHideTeamMemberModal(false);
          }}
        >
          Reopen
        </StyledConfirmButton>
      </StyledModalButtonContainer>
    </Modal>
  );
};
export default NewUpdatesDialog;
