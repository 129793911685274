import { Close } from '@styled-icons/material';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';

type Props = {
  width?: number;
  height?: number;
  color?: string;
  onClick?: MouseEventHandler;
};
const StyledIcon = styled(Close)<{ width: number; height: number }>`
  width: ${(props) => (props?.width ? `${props.width}px` : 'auto')};
  height: ${(props) => (props?.height ? `${props.height}px` : 'auto')};
  color: ${(props) => (props?.color ? props.color : '#1d1e2b')};
  cursor: pointer;
`;
const CloseIcon = ({ width = 20, height = 20, onClick, color }: Props): JSX.Element => {
  return <StyledIcon width={width} height={height} onClick={onClick} color={color} />;
};

export default CloseIcon;
